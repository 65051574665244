<template>
    <div>
        <NuxtLoadingIndicator></NuxtLoadingIndicator>
        <div class="bg-primary-500 h-1">
        </div>
        <ClientOnly>
            <div class="bg-brand-500 flex justify-between pr-8 items-center">
                <div class="px-6 py-2 flex">
                    <img src="/images/logobkwhite.png" alt="logo" class="h-[46px]" />
                    <img src="/images/logtext.png" alt="logo" class="h-[36px] mt-2" />
                </div>
                <nav class="text-slate-100 ">

                    <ul v-if="user?.roles?.includes('isDbbs')" class="flex space-x-4 items-center">
                        <li class="flex items-center space-x-2" v-for="m in menu">
                            <VaIcon :name="m.icon" size="large" />
                            <NuxtLink :to="m.link">{{ m.name }}</NuxtLink>
                        </li>                        

                        <!-- <li><NuxtLink to="/">Home</NuxtLink></li>
                    <li><NuxtLink to="/dbbs/dbo">DBO</NuxtLink></li>
                    <li><NuxtLink to="/dbbs/dbs">DBS</NuxtLink></li>
                    <li><NuxtLink to="/dbbs/admin">Admin</NuxtLink></li>
                    <li><NuxtLink to="/client/location">Location</NuxtLink></li>
                    <li><NuxtLink to="/client/review">Review</NuxtLink></li>
                    <li><NuxtLink to="/client/hq">Hq</NuxtLink></li> -->
                        <li v-if="user">{{ user?.email }} | <button @click="logout">Logout</button></li>
                        <li v-else><button @click="logout">Log out</button></li>

                    </ul>
                    <ul v-else-if="user?.roles?.includes('isClientHq')" class="flex space-x-4 items-center">
                        <li class="flex items-center space-x-2" v-for="m in menu">
                            <VaIcon :name="m.icon" size="large" />
                            <NuxtLink :to="m.link">{{ m.name }}</NuxtLink>
                        </li> 
                        <li v-if="isLoggedIn">{{ user?.email }} | <button @click="logout">Logout</button></li>
                        <!-- <li><button @click="logout">Log out</button></li>                        -->
                    </ul>
                    <ul v-else class="flex space-x-4 items-center">
                        <li v-if="isLoggedIn">{{ user?.email }} | <button @click="logout">Logout</button></li>
                        <li v-else><NuxtLink :to="'/auth/login'">Log In</NuxtLink></li>
                    </ul>
                </nav>
                
            </div>
        </ClientOnly>
    </div>
</template>
<script setup lang="ts">

const authStore = useAuthUserStore()
const {user, isLoggedIn} = storeToRefs(authStore)
const logout = async () => {
    await authStore.signout()
}

const { menu } = menuController(user?.value?.roles ?? [])



function menuController(lst: string[] | undefined) {
    const menuList: Record<string, { name: string, icon: string, link: string }[]> = {
        "isDbAdmin": [
            { name: "Home", icon: 'home', link: "/dbbs/admin" },
            { name: "Setup", icon: '', link: "/dbbs/ad/setup" },
            { name: "Team", icon: '', link: "/dbbs/ad/team" },
            { name: "Archives", icon: '', link: "/dbbs/archives" },
            { name: "Executive Summary", icon: '', link: "/client/hqexecutive" },
            { name: "Help", icon: '', link: "/dbbs/ad/content" },
        ],
        "isDbo": [
            { name: "Home", icon: 'home', link: "/dbbs/dbo" },
            { name: "Processing", icon: '', link: "/dbbs/dboarea/processing" },
            { name: "Resolution Center", icon: '', link: "/dbbs/dbsarea/resolution" },
            { name: "Archives", icon: '', link: "/dbbs/archives" },
            {name: "Reports", icon: '', link: "/dbbs/dboarea/dboreports"},
        ],
        "isDbs": [
            { name: "Home", icon: 'home', link: "/dbbs/dbs" },
            { name: "Assign Work", icon: '', link: "/dbbs/dbsarea/assignWork" },
            { name: "Manage Work", icon: '', link: "/dbbs/dbsarea/approve" },
            { name: "Resolution Center", icon: '', link: "/dbbs/dbsarea/resolution" },
            { name: "Archives", icon: '', link: "/dbbs/archives" },
        ],
        "isUploader": [
            { name: "Home", icon: 'home', link: "/client/location" },
            { name: "Review", icon: '', link: "/client/uploads" },
        ],
        "isClientSupervisor": [
        { name: "Home", icon: 'home', link: "/" },
            { name: "Home", icon: 'home', link: "/client/review" },
            { name: "Resolution Center", icon: '', link: "/dbbs/dbsarea/resolution" },
            { name: "Archives", icon: '', link: "/dbbs/archives" },
        ],
        "isClientHq": [
            { name: "Home", icon: 'home', link: "/client/hq" },
            { name: "Executive Summary", icon: '', link: "/client/hqexecutive" },
           // { name: "Hq Reports", icon: '', link: "/client/hqreports" },
            { name: "Archives", icon: '', link: "/dbbs/archives" },
        ],

    }
    if (lst === undefined) return { menu: [] }
    const menu = ref<string>("")
    lst = removeIsDbbsFromList(lst)
    const item = lst[0]
    const rntMenu = menuList[item]



    function removeIsDbbsFromList(lst: string[]) {
        return lst.filter((i) => i !== "isDbbs")
    }
    return { menu: rntMenu }
}
</script>